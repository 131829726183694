import DOMPurify from 'dompurify';

/**
 * Sanitiza o HTML fornecido para prevenir XSS.
 * @param dirtyHtml - O HTML não sanitizado.
 * @returns O HTML sanitizado.
 */

export function sanitizeHtml(dirtyHtml: string): string {
  return DOMPurify.sanitize(dirtyHtml);
}
