import React, { Fragment, useMemo, useRef, useState } from "react";
import {
  Alert,
  Badge,
  Button,
  Col,
  Modal,
  OverlayTrigger,
  Row,
  Spinner,
  Tab,
  Tabs,
  Tooltip,
} from "react-bootstrap";
import {
  MdEdit,
  MdExpandLess,
  MdExpandMore,
  MdUploadFile,
  MdVisibility
} from "react-icons/md";
import { useHistory, useParams } from "react-router-dom";
import { RequestDetailsProps } from "../../@types/requests";
import Loading from "../../Components/Loading";
import Status from "../../Components/Status";
import { getRequestForId } from "../../services/requests";
import { cpfMask } from "../../utils/cpfFormat";
import dateFormat from "../../utils/dateFormat";
import StatusRequest from "../Requests/Components/StatusRequest";
import {
  AssignmentIcon,
  BoxBodyCard,
  BoxBodyCardTable,
  BoxButtonsAttachments,
  BoxHeaderCard,
  BoxTextsTitleCard,
  BoxTimeLine,
  ButtonAttachment,
  ButtonReturn,
  Card,
  CardFooterCustom,
  ContainerMain,
  CustomTable,
  InfoIcon,
  SubTextMark,
  TextMark,
  TextsTitleCard,
  TimeLine,
  TimeLineContainer,
  TimeLineContent,
  TimeLineContentBoxText,
  TimeSubTitle,
  TimeTitle,
  TitleHeadCard,
} from "./styles";
import { sanitizeHtml } from "../../utils/sanitizeHtml";

type Params = {
  identificador: string;
};

const RequestDetails = (): JSX.Element => {
  const downloadLink = useRef(null);
  const [, setIsDocumentLoaded] = useState(true);
  const [loadingAttachments, setLoadingAttachments] = useState(false);
  const history = useHistory();
  const [countAllDocumentUnreaded, setCountAllDocumentUnreaded] =
    React.useState<number>(0);
  const { identificador } = useParams<Params>();
  const [loading, setLoading] = React.useState(false);
  const [requestData, setRequestData] = React.useState<
    RequestDetailsProps | undefined
  >(undefined);
  const [showDetails, setShowDetails] = React.useState<string>("");
  const [showModalDocReceived, setShowModalDocReceived] =
    React.useState<boolean>(false);
  const [docReceivedSelected, setDocReceivedSelected] = React.useState<{
    id: string;
    nome: string;
    url: string;
  }>();
  const [showModalAnexos, setShowModalAnexos] = React.useState<boolean>(false);
  const [anexosReceived, setAnexosReceivedSelected] = React.useState<{
    id: string;
    arquivo: string;
  }>();
  const handleNew = (): void => {
    history.push(`/solicitacoes/cadastra-tramite/${identificador}`);
  };
  const handleAttachDocument = (): void => {
    history.push(
      `/solicitacoes/${identificador}/${requestData.id}/anexar-documento/`
    );
  };
  const handleEditDocument = (
    id: number,
    id_orgao: number,
    identificador: string,
    id_documento: string
  ): void => {
    history.push(
      `/solicitacoes/${id}/${id_orgao}/documento/${identificador}/editar-documento/${id_documento}`
    );
  };
  const handleShowDocument = (
    id: string,
    id_orgao: number,
    id_document: string
  ): void => {
    history.push(
      `/solicitacoes/${id}/${id_orgao}/documento/${id_document}/visualizar`
    );
  };

  const handleShowDocReceived = (doc: {
    id: string;
    nome: string;
    url: string;
  }): void => {
    setDocReceivedSelected(doc);
    setShowModalDocReceived(true);
  };

  const handleShowAnexosReceived = (doc: {
    id: string;
    arquivo: string;
  }): void => {
    setAnexosReceivedSelected(doc);
    setShowModalAnexos(true);
  }
  React.useEffect(() => {
    const getDetailsRequest = async () => {
      try {
        setLoading(true);
        const { data } = await getRequestForId(identificador);
        if (data?.documentos_solicitacao) {
          let count = 0;
          data.documentos_solicitacao.forEach((doc: any) => {
            if (doc.verificado_em === null) {
              count += 1;
            }
          });
          setCountAllDocumentUnreaded(count);
        }
        setRequestData(data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    getDetailsRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleIframeLoad = () => {
    setLoadingAttachments(false);
  };

  const handleIframeError = () => {
    setIsDocumentLoaded(false);
    setLoadingAttachments(false);
  };

  const isImage = (url) => {
    return url && /\.(jpg|jpeg|png)$/i.test(url);
  };

  const checkHasDocumentRequestPending = useMemo(() => {
    let allPending = requestData?.progresso?.length > 0 && requestData?.progresso.filter((progress) => progress.concluido === false);
    if (allPending?.length > 1) {
      return false;
    }

    return (requestData?.progresso?.length > 0 && requestData?.progresso[requestData.progresso.length - 1].concluido === false);
  }, [requestData?.progresso]);

  const findCountDocumentRequestPending = useMemo(() => {
    return (requestData?.documentos_resposta?.some((document) => !document.assinado));
  }, [requestData?.documentos_resposta]);


  const getCountDocumentRequestPending = useMemo(() => {
    return (requestData?.documentos_resposta?.filter((document) => !document.assinado)?.length);
  }, [requestData?.documentos_resposta]);

  return (
    <ContainerMain>
      <Row>
        <Col sm={12} md={5}>
          <Card>
            <BoxHeaderCard>
              <TitleHeadCard>
                <InfoIcon />
                <BoxTextsTitleCard>
                  <TextsTitleCard>Informações da solicitação</TextsTitleCard>
                </BoxTextsTitleCard>
              </TitleHeadCard>
            </BoxHeaderCard>
            {loading ? (
              <Loading />
            ) : (
              <BoxBodyCard>
                <TextMark>Protocolo</TextMark>
                <SubTextMark>
                  {requestData?.protocolo || "Sem informação"}
                </SubTextMark>

                <TextMark>Hash</TextMark>
                <SubTextMark>
                  {requestData?.hash || "Sem informação"}
                </SubTextMark>

                <TextMark>Identificador</TextMark>
                <SubTextMark>
                  {requestData?.identificador || "Sem informação"}
                </SubTextMark>

                <TextMark>CPF</TextMark>
                <SubTextMark>{cpfMask(requestData?.cpf || "")}</SubTextMark>

                <TextMark>Fluxo</TextMark>
                <SubTextMark>
                  {requestData?.flow?.titulo || "Sem informação"}
                </SubTextMark>

                <TextMark>Assinado pelo cidadão</TextMark>
                <Status type={requestData?.assinado?.toString() || "false"} />
              </BoxBodyCard>
            )}
          </Card>
        </Col>
        <Col sm={12} md={7}>
          <Card>
            <BoxHeaderCard>
              <TitleHeadCard>
                <InfoIcon />
                <BoxTextsTitleCard>
                  <TextsTitleCard>Tramitação</TextsTitleCard>
                </BoxTextsTitleCard>
              </TitleHeadCard>
              {(requestData?.status === "Aguardando" ||
                requestData?.status === "Solicitado" ||
                requestData?.status === "Em Andamento" ||
                requestData?.status === "Encaminhado") && (
                  <>
                    <Button
                      variant="primary"
                      onClick={handleNew}
                      disabled={(!!countAllDocumentUnreaded) || (checkHasDocumentRequestPending && findCountDocumentRequestPending)}
                    >
                      <MdEdit /> Tramitar
                    </Button>
                  </>
                )}
            </BoxHeaderCard>
            {!!countAllDocumentUnreaded && (
              <Alert
                variant="primary"
                style={{ marginLeft: 5, marginRight: 5 }}
              >
                {countAllDocumentUnreaded > 1
                  ? `Existem ${countAllDocumentUnreaded} documentos da solicitação para serem verificados antes da tramitação`
                  : ` Existe ${countAllDocumentUnreaded} documento da solicitação para ser verificado antes da tramitação`}
              </Alert>
            )}
            {(checkHasDocumentRequestPending && findCountDocumentRequestPending) && (
              <Alert
                variant="primary"
                style={{ marginLeft: 5, marginRight: 5 }}
              >
                {getCountDocumentRequestPending > 1
                  ? `Existe ${getCountDocumentRequestPending} documentos pendente de assinatura`
                  : `Existe um documento pendente de assinatura`
                }
              </Alert>
            )}
            {loading ? (
              <Loading />
            ) : (
              <BoxBodyCard
                style={countAllDocumentUnreaded ? { minHeight: "476px" } : {}}
              >
                <Row>
                  <Col>
                    <TextMark>Órgão</TextMark>
                    <SubTextMark>
                      {requestData?.orgao?.nome || "Sem informação"}
                    </SubTextMark>
                  </Col>
                  <Col>
                    <TextMark>Setor</TextMark>
                    <SubTextMark>
                      {requestData?.setor?.nome || "Sem informação"}
                    </SubTextMark>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <TextMark>Atendente que assinou</TextMark>
                    <SubTextMark>
                      {requestData?.nome_atendente_assinado || "Sem informação"}
                    </SubTextMark>
                  </Col>
                  <Col>
                    <TextMark>Hash de assinatura do atendente</TextMark>
                    <SubTextMark>
                      {requestData?.hash_atendente || "Sem informação"}
                    </SubTextMark>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <TextMark>Descrição</TextMark>
                    <SubTextMark
                      dangerouslySetInnerHTML={{
                        __html: sanitizeHtml(requestData?.descricao) || "Sem informação",
                      }}
                    />
                  </Col>
                  <Col>
                    <TextMark>Status</TextMark>
                    <SubTextMark>
                      {requestData?.status || "Sem informação"}
                    </SubTextMark>
                  </Col>
                </Row>
              </BoxBodyCard>
            )}
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <BoxHeaderCard>
              <TitleHeadCard>
                <InfoIcon />
                <BoxTextsTitleCard>
                  <TextsTitleCard>Outras Informações</TextsTitleCard>
                </BoxTextsTitleCard>
              </TitleHeadCard>
            </BoxHeaderCard>
            <BoxBodyCardTable>
              <Tabs defaultActiveKey="history" className="mx-4">
                <Tab eventKey="history" title="Histórico">
                  <CustomTable striped responsive>
                    <thead className="table-header">
                      <tr>
                        <th>Ordem</th>
                        <th>Status</th>
                        <th>Identificador</th>
                        <th>Tramitado em</th>
                        <th>Atendente</th>
                        <th>Ações</th>
                      </tr>
                    </thead>
                    <tbody className="table-body">
                      {loading ? (
                        <tr className="text-center">
                          <td colSpan={6}>
                            <Loading />
                          </td>
                        </tr>
                      ) : (
                        <>
                          {requestData?.historico.length ? (
                            requestData?.historico?.map((item, index) => (
                              <Fragment key={item.identificador}>
                                <tr>
                                  <td>#{index + 1}</td>
                                  <td>
                                    <StatusRequest type={item.status} />
                                  </td>
                                  <td>{item.identificador}</td>
                                  <td>{dateFormat(item.updated_at, true)}</td>
                                  <td>{item.assinado_por === "Atendente" ? 'Não consta' : item.assinado_por}</td>
                                  <td className="text-right">
                                    <Button
                                      variant="outline-secondary"
                                      onClick={() => {
                                        showDetails &&
                                          showDetails === item.identificador
                                          ? setShowDetails("")
                                          : setShowDetails(item.identificador);
                                      }}
                                    >
                                      {showDetails &&
                                        showDetails === item.identificador ? (
                                        <MdExpandMore />
                                      ) : (
                                        <MdExpandLess />
                                      )}
                                    </Button>
                                  </td>
                                </tr>
                                {showDetails &&
                                  showDetails === item.identificador && (
                                    <>
                                      <tr className="hiddenDetails">
                                        <th colSpan={3}>DESCRIÇÃO</th>
                                        <td colSpan={3} className="text-right" dangerouslySetInnerHTML={{ __html: sanitizeHtml(item?.descricao) }}>
                                        </td>
                                      </tr>
                                      <tr className="hiddenDetails">
                                        <th colSpan={3}>ÓRGÃO</th>
                                        <td colSpan={3} className="text-right">
                                          {item.orgao}
                                        </td>
                                      </tr>
                                      <tr className="hiddenDetails">
                                        <th colSpan={3}>HASH</th>
                                        <td colSpan={3} className="text-right">
                                          {item.hash}
                                        </td>
                                      </tr>
                                      <tr className="hiddenDetails">
                                        <th colSpan={3}>ASSINADO POR</th>
                                        <td colSpan={3} className="text-right">
                                          {item.assinado_por === "Atendente" ? 'Não consta' : item.assinado_por}
                                        </td>
                                      </tr>
                                    </>
                                  )}
                              </Fragment>
                            ))
                          ) : (
                            <tr className="text-center">
                              <td colSpan={6}>Nenhum histórico encontrado</td>
                            </tr>
                          )}{" "}
                        </>
                      )}
                    </tbody>
                  </CustomTable>
                </Tab>
                <Tab
                  eventKey="doc_request"
                  title={
                    <>
                      Documentos da solicitação{" "}
                      {countAllDocumentUnreaded > 0 && (
                        <OverlayTrigger
                          placement="right"
                          delay={{ show: 250, hide: 400 }}
                          overlay={
                            <Tooltip id="button-tooltip">
                              {countAllDocumentUnreaded > 1
                                ? `Documentos não verificados: ${countAllDocumentUnreaded}`
                                : `Documento não verificado: ${countAllDocumentUnreaded}`}
                            </Tooltip>
                          }
                        >
                          <Badge bg="primary">{countAllDocumentUnreaded}</Badge>
                        </OverlayTrigger>
                      )}
                    </>
                  }
                >
                  <CustomTable striped responsive>
                    <thead className="table-header">
                      <tr>
                        <th>Ordem</th>
                        <th>Título</th>
                        <th>Conformidade</th>
                        <th>Verificado em</th>
                        <th>Ações</th>
                      </tr>
                    </thead>
                    <tbody className="table-body">
                      {loading ? (
                        <tr className="text-center">
                          <td colSpan={5}>
                            <Loading />
                          </td>
                        </tr>
                      ) : (
                        <>
                          {" "}
                          {requestData?.documentos_solicitacao.length ? (
                            requestData?.documentos_solicitacao?.map(
                              (item, index) => (
                                <Fragment key={item.id}>
                                  <tr>
                                    <td>#{index + 1}</td>
                                    <td>{item.titulo}</td>
                                    <td>
                                      <Status
                                        type={item?.conformidade.toString()}
                                      />
                                    </td>
                                    <td>
                                      {item.verificado_em
                                        ? dateFormat(item.verificado_em, true)
                                        : "Sem informação"}
                                    </td>
                                    <td className="text-right">
                                      <Button
                                        variant="outline-secondary mx-2"
                                        onClick={() =>
                                          history.push(
                                            `/solicitacoes/documento/${item?.id}/${requestData.identificador}/visualizar-documentos`
                                          )
                                        }
                                      >
                                        <MdVisibility size={22} />
                                      </Button>
                                      <Button
                                        variant="outline-secondary"
                                        onClick={() => {
                                          showDetails && showDetails === item.id
                                            ? setShowDetails("")
                                            : setShowDetails(item.id);
                                        }}
                                      >
                                        {showDetails &&
                                          showDetails === item.id ? (
                                          <MdExpandMore />
                                        ) : (
                                          <MdExpandLess />
                                        )}
                                      </Button>
                                    </td>
                                  </tr>
                                  {showDetails && showDetails === item.id && (
                                    <>
                                      <tr className="hiddenDetails">
                                        <th colSpan={3}>VERIFICADO POR</th>
                                        <td colSpan={2} className="text-right">
                                          {!item.verificado_por &&
                                            "Não informado"
                                          }
                                        </td>
                                      </tr>
                                      {item.verificado_por &&
                                        <td colSpan={2} style={{ padding: '15px 30px' }}>
                                          <>
                                            <div>Nome:  {item.verificado_por?.nome} </div>
                                            <div>CPF:  {item.verificado_por?.cpf} </div>
                                            <div>
                                              Data da Verificação:
                                              {new Date(item?.verificado_em).toLocaleDateString("pt-BR")}
                                            </div>
                                          </>
                                        </td>
                                      }
                                    </>
                                  )}
                                </Fragment>
                              )
                            )
                          ) : (
                            <tr className="text-center">
                              <td colSpan={5}>Nenhum histórico encontrado</td>
                            </tr>
                          )}
                        </>
                      )}
                    </tbody>
                  </CustomTable>
                </Tab>
                <Tab
                  eventKey="doc_response"
                  title={
                    <>
                      Documentos de  Resposta{" "}
                      {(checkHasDocumentRequestPending && findCountDocumentRequestPending) && (
                        <OverlayTrigger
                          placement="right"
                          delay={{ show: 250, hide: 400 }}
                          overlay={
                            <Tooltip id="button-tooltip">
                              {getCountDocumentRequestPending > 1
                                ? `Documentos não assinados: ${getCountDocumentRequestPending}`
                                : `Documento não assinado: ${getCountDocumentRequestPending}`}
                            </Tooltip>
                          }
                        >
                          <Badge bg="primary">{getCountDocumentRequestPending}</Badge>
                        </OverlayTrigger>
                      )}
                    </>
                  }>
                  <CustomTable striped responsive>
                    <thead className="table-header">
                      <tr>
                        <th>Ordem</th>
                        <th>Título</th>
                        <th>Assinado</th>
                        <th>Criado em</th>
                        <th>Ações</th>
                      </tr>
                    </thead>
                    <tbody className="table-body">
                      {loading ? (
                        <tr className="text-center">
                          <td colSpan={5}>
                            <Loading />
                          </td>
                        </tr>
                      ) : (
                        <>
                          {" "}
                          {requestData?.documentos_resposta.length ? (
                            requestData?.documentos_resposta?.map(
                              (item, index) => (
                                <Fragment key={item.id}>
                                  <tr>
                                    <td>#{index + 1}</td>
                                    <td>{item.titulo}</td>
                                    <td>
                                      <Status
                                        type={item?.assinado.toString()}
                                      />
                                    </td>
                                    <td>{dateFormat(item.created_at, true)}</td>
                                    <td className="text-right">
                                      {!item.assinado ? (
                                        <>
                                          <Button
                                            variant="outline-secondary mx-2"
                                            onClick={() =>
                                              handleEditDocument(
                                                requestData?.id,
                                                requestData?.orgao.id,
                                                requestData?.identificador,
                                                item.id.toString()
                                              )
                                            }
                                          >
                                            <MdEdit size={22} />
                                          </Button>
                                        </>
                                      ) : (
                                        <Button
                                          variant="outline-secondary mx-2"
                                          onClick={() =>
                                            handleShowDocument(
                                              requestData?.identificador,
                                              requestData?.orgao.id,
                                              item.id
                                            )
                                          }
                                        >
                                          <MdVisibility size={22} />
                                        </Button>
                                      )}

                                      <Button
                                        variant="outline-secondary"
                                        onClick={() => {
                                          showDetails && showDetails === item.id
                                            ? setShowDetails("")
                                            : setShowDetails(item.id);
                                        }}
                                      >
                                        {showDetails &&
                                          showDetails === item.id ? (
                                          <MdExpandMore />
                                        ) : (
                                          <MdExpandLess />
                                        )}
                                      </Button>
                                    </td>
                                  </tr>
                                  {showDetails && showDetails === item.id && (
                                    <>
                                      <tr className="hiddenDetails">
                                        <th colSpan={3}>Criado por</th>
                                        <td colSpan={2} className="text-right">
                                          {item.criado_por || "Não informado"}
                                        </td>
                                      </tr>
                                      <tr className="hiddenDetails">
                                        <th colSpan={3}>HASH</th>
                                        <td colSpan={2} className="text-right">
                                          {item.hash || "Não informado"}
                                        </td>
                                      </tr>
                                    </>
                                  )}
                                </Fragment>
                              )
                            )
                          ) : (
                            <tr className="text-center">
                              <td colSpan={5}>Nenhum histórico encontrado</td>
                            </tr>
                          )}{" "}
                        </>
                      )}
                    </tbody>
                  </CustomTable>
                </Tab>
                <Tab eventKey="docs_received" title="Anexos recebidos">
                  <CustomTable striped responsive>
                    <thead className="table-header">
                      <tr>
                        <th>Ordem</th>
                        <th>Nome</th>
                        <th>Hash</th>
                        <th>Ações</th>
                      </tr>
                    </thead>
                    <tbody className="table-body">
                      {loading ? (
                        <tr className="text-center">
                          <td colSpan={5}>
                            <Loading />
                          </td>
                        </tr>
                      ) : (
                        <>
                          {" "}
                          {requestData?.arquivos_recebidos.length ? (
                            requestData?.arquivos_recebidos?.map(
                              // eslint-disable-next-line array-callback-return
                              (item, index) => {
                                if (item.id) {
                                  return (
                                    <Fragment key={item.id}>
                                      <tr>
                                        <td>#{index + 1}</td>
                                        <td>{item.nome}</td>
                                        <td>{item.id}</td>
                                        <td className="text-right">
                                          <Button
                                            variant="outline-secondary mx-2"
                                            onClick={() =>
                                              handleShowDocReceived(item)
                                            }
                                          >
                                            <MdVisibility size={22} />
                                          </Button>
                                        </td>
                                      </tr>
                                    </Fragment>
                                  );
                                }
                              }
                            )
                          ) : (
                            <tr className="text-center">
                              <td colSpan={5}>Nenhum histórico encontrado</td>
                            </tr>
                          )}{" "}
                        </>
                      )}
                    </tbody>
                  </CustomTable>
                </Tab>
                <Tab eventKey="attach_doc" title="Anexos de resposta">
                  <BoxButtonsAttachments>
                    <ButtonAttachment
                      variant="primary"
                      onClick={handleAttachDocument}
                    >
                      <MdUploadFile size={20} /> Anexar documento
                    </ButtonAttachment>
                  </BoxButtonsAttachments>
                  <CustomTable striped responsive>
                    <thead className="table-header">
                      <tr>
                        <th>Ordem</th>
                        <th>Título</th>
                        <th className="text-right">Ações</th>
                      </tr>
                    </thead>
                    <tbody className="table-body">
                      {loading ? (
                        <tr className="text-center">
                          <td colSpan={5}>
                            <Loading />
                          </td>
                        </tr>
                      ) : (
                        <>
                          {requestData?.anexo_documentos_resposta.length ? (
                            requestData?.anexo_documentos_resposta?.map(
                              (item, index) => (
                                <Fragment key={item.id}>
                                  <tr>
                                    <td>#{index + 1}</td>
                                    <td>{item.assunto}</td>
                                    <td className="text-right">
                                      <Button
                                        variant="outline-secondary mx-2"
                                        onClick={() => {
                                          handleShowDocReceived({
                                            id: item.id,
                                            nome: item.assunto,
                                            url: item.arquivo,
                                          })
                                        }}
                                      >
                                        <MdVisibility size={22} />
                                      </Button>
                                      <Button
                                        variant="outline-secondary"
                                        onClick={() => {
                                          showDetails && showDetails === item.id
                                            ? setShowDetails("")
                                            : setShowDetails(item.id);
                                        }}
                                      >
                                        {showDetails &&
                                          showDetails === item.id ? (
                                          <MdExpandMore />
                                        ) : (
                                          <MdExpandLess />
                                        )}
                                      </Button>
                                    </td>
                                  </tr>
                                  {showDetails && showDetails === item.id && (
                                    <>
                                      <tr className="hiddenDetails">
                                        <th colSpan={2}>Criado por:</th>
                                        <td className="text-right">
                                          {item.criado_por || "Não informado"}
                                        </td>
                                      </tr>
                                      <tr className="hiddenDetails">
                                        <th colSpan={3}>Descrição:</th>
                                      </tr>
                                      <tr>
                                        <td colSpan={3}>
                                          <div dangerouslySetInnerHTML={{
                                            __html: sanitizeHtml(item.descricao)
                                          }} />
                                        </td>
                                      </tr>
                                    </>
                                  )}
                                </Fragment>
                              )
                            )
                          ) : (
                            <tr className="text-center">
                              <td colSpan={5}>Nenhum anexo encontrado</td>
                            </tr>
                          )}{" "}
                        </>
                      )}
                    </tbody>
                  </CustomTable>
                </Tab>
                {requestData?.restituicao?.length > 0 && (
                  <Tab eventKey="info" title="Informações complementares">
                    <CustomTable striped responsive>
                      <thead className="table-header">
                        <tr>
                          <th>Ordem</th>
                          <th>Resposta</th>
                          <th>Tramitado em</th>
                          <th>Ações</th>
                        </tr>
                      </thead>
                      <tbody className="table-body">
                        {loading ? (
                          <tr className="text-center">
                            <td colSpan={5}>
                              <Loading />
                            </td>
                          </tr>
                        ) : (
                          <>
                            {" "}
                            {requestData?.restituicao?.length ? (
                              requestData?.restituicao?.map((item, index) => (
                                <Fragment key={item.id}>
                                  <tr>
                                    <td>#{index + 1}</td>
                                    <td>
                                      {item.resposta}
                                    </td>
                                    <td>{dateFormat(item.updated_at, true)}</td>
                                    <td className="text-right">
                                      <Button
                                        variant="outline-secondary"
                                        onClick={() => {
                                          showDetails &&
                                            showDetails === item.id
                                            ? setShowDetails("")
                                            : setShowDetails(item.id);
                                        }}
                                      >
                                        {showDetails &&
                                          showDetails === item.id ? (
                                          <MdExpandMore />
                                        ) : (
                                          <MdExpandLess />
                                        )}
                                      </Button>
                                    </td>
                                  </tr>
                                  {showDetails &&
                                    showDetails === item.id && (
                                      <>
                                        {item.anexos?.length > 0 ? (
                                          <>
                                            {item.anexos.map((anexo, ind) => (
                                              <tr>
                                                <td>#{ind + 1}</td>
                                                <td>{`${ind + 1} - Anexo`}</td>
                                                <td></td>
                                                <td className="text-right">
                                                  <Button
                                                    variant="outline-secondary"
                                                    onClick={() => handleShowAnexosReceived(anexo)}
                                                  >
                                                    <MdVisibility size={22} />
                                                  </Button>
                                                </td>
                                              </tr>
                                            ))}
                                          </>
                                        ) : (
                                          <tr className="text-center">
                                            <td colSpan={5}>Nenhum anexo encontrado.</td>
                                          </tr>
                                        )}
                                      </>
                                    )}
                                </Fragment>
                              ))
                            ) : (
                              <tr className="text-center">
                                <td colSpan={5}>Nenhum histórico encontrado</td>
                              </tr>
                            )}{" "}
                          </>
                        )}
                      </tbody>
                    </CustomTable>
                  </Tab>
                )}
                <Tab eventKey="progress" title="Progresso">
                  {loading ? (
                    <Loading />
                  ) : (
                    <>
                      <BoxTimeLine>
                        {requestData?.progresso?.length ? (
                          requestData?.progresso?.map((item, index) => (
                            <TimeLine key={index.toString()}>
                              <TimeLineContainer
                                content={item.ordem.toString()}
                                checked={item.concluido}
                              >
                                <TimeLineContent>
                                  <AssignmentIcon size={20} />
                                  <TimeLineContentBoxText>
                                    <TimeTitle>{item.atividade}</TimeTitle>
                                    <TimeSubTitle>{item.orgao}</TimeSubTitle>
                                  </TimeLineContentBoxText>
                                </TimeLineContent>
                              </TimeLineContainer>
                            </TimeLine>
                          ))
                        ) : (
                          <>
                            <h1>Nenhum progresso encontrado</h1>
                          </>
                        )}
                      </BoxTimeLine>
                    </>
                  )}
                </Tab>
                <Tab eventKey="docs_intern" title="Anexos internos">
                  <CustomTable striped responsive>
                    <thead className="table-header">
                      <tr>
                        <th>Ordem</th>
                        <th>Nome</th>
                        <th>Hash</th>
                        <th>Ações</th>
                      </tr>
                    </thead>
                    <tbody className="table-body">
                      {loading ? (
                        <tr className="text-center">
                          <td colSpan={5}>
                            <Loading />
                          </td>
                        </tr>
                      ) : (
                        <>
                          {requestData?.anexos_internos?.length ? (
                            requestData?.anexos_internos?.map(
                              // eslint-disable-next-line array-callback-return
                              (item, index) => {
                                const parts = item.arquivo.split('/');
                                const fileName = parts[parts.length - 1];
                                if (item.id) {
                                  return (
                                    <Fragment key={item.id}>
                                      <tr>
                                        <td>#{index + 1}</td>
                                        <td>{fileName}</td>
                                        <td>{item.id}</td>
                                        <td className="text-right">
                                          <Button
                                            variant="outline-secondary mx-2"
                                            onClick={() => {
                                              handleShowDocReceived({
                                                id: item.id,
                                                nome: fileName,
                                                url: item.arquivo,
                                              })
                                            }}
                                          >
                                            <MdVisibility size={22} />
                                          </Button>
                                        </td>
                                      </tr>
                                    </Fragment>
                                  );
                                }
                              }
                            )
                          ) : (
                            <tr className="text-center">
                              <td colSpan={5}>Nenhum histórico encontrado</td>
                            </tr>
                          )}{" "}
                        </>
                      )}
                    </tbody>
                  </CustomTable>
                </Tab>
              </Tabs>
            </BoxBodyCardTable>
            <CardFooterCustom>
              <ButtonReturn onClick={() => history.push("/solicitacoes")}>
                Voltar
              </ButtonReturn>
            </CardFooterCustom>
          </Card>
        </Col>
      </Row>
      <Modal
        show={showModalDocReceived}
        onHide={() => setShowModalDocReceived(false)}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>{docReceivedSelected?.nome}</Modal.Title>

        </Modal.Header>
        <Modal.Body style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '750px' }}>
          {isImage(docReceivedSelected?.url) ? (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
              <img
                src={docReceivedSelected?.url}
                alt={docReceivedSelected?.nome}
                style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
              />
            </div>
          ) : (
            <>
              {loadingAttachments && <Spinner />}
              <iframe
                title={docReceivedSelected?.nome}
                src={`https://docs.google.com/gview?url=${encodeURIComponent(docReceivedSelected?.url)}&embedded=true`}
                style={{
                  width: "100%",
                  height: "750px",
                  border: "none",
                  display: loadingAttachments ? 'none' : 'block'
                }}
                onLoad={handleIframeLoad}
                onError={handleIframeError}
              />
              <div style={{ textAlign: 'center' }}>
                <p>Se o documento não foi carregado automaticamente, clique no botão abaixo para fazer o download.</p>
              </div>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <a
            ref={downloadLink}
            href={docReceivedSelected?.url || "#"}
            download
            className="btn btn-primary"
            style={{ marginLeft: '10px' }}
          >
            Download
          </a>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showModalAnexos}
        onHide={() => setShowModalAnexos(false)} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>Arquivo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <object
            aria-label={anexosReceived?.id}
            data={anexosReceived?.arquivo || ''}
            style={{
              width: '100%',
              minHeight: 900,
            }} />
        </Modal.Body>
      </Modal>
    </ContainerMain>
  );
};

export default RequestDetails;
